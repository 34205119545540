/* eslint-disable jsx-a11y/alt-text */
import "./main.css";
export const Home = () => {
  return (
    <div>
      <div className="back" />
      <div className="box">
        <div className="top">
          <img src={require("./pic.jpeg")} />
          <p className="title">Dipankar Dutta</p>
          <p className="subtitle">
            Dipankar Dutta is thinking, writing and talking about Software Systems Architecture: how they built, how they break, how they scale, and
            the people that maintain them.
          </p>
          <p className="subtitle">
            I’m currently living in{" "}
            <a className="url" href="https://www.visitlondon.com/">
              London
            </a>
            ,UK and working as a software enginning lead for{" "}
            <a className="url" href="https://facebook.com">
              Facebook
            </a>
            . I help to create and maintain the software product for{" "}
            <a className="url" href="https://portal.facebook.com/">
              Portal
            </a>{" "}
            from Facebook. Our team builds software components, patterns, guidance, and tools to enhance the experiences for billions of people.
            Previously, I worked as a software devlopment engineer at{" "}
            <a className="url" href="http://office.microsoft.com/">
              Microsoft
            </a>{" "}
            and{" "}
            <a className="url" href="https://www.amazon.co.in/">
              Amazon
            </a>
          </p>
          <p className="subtitle">
            I spend my time travling, taking photographs, making bengali art &amp; music, and writing code. I also write about design, courage, and
            lack thereof.
          </p>
          <p className="subtitle">
            I’m currently unavailable for work, but you can take a look at my{" "}
            <a className="url" href="http://dipankar08.github.io/apps/resume/index.html">
              résumé
            </a>{" "}
            anyway.
          </p>
        </div>
        <div className="buttom flex-col">
          <div className="flex-row">
            <ul>
              <li className="head">Books</li>
              <li>
                <a href="http://dipankar08.github.io/apps/books/allinone_typescript.txt.html">TypeScript</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/books/android_examples.txt.html">Android</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/books/pro_devlopers.txt.html">Linux Devs</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/books/design_principles.txt.html">Design Principles</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/books/pro_computervision.txt.html">Pro Computer Vison</a>
              </li>
            </ul>
            <ul>
              <li className="head">Products</li>
              <li>
                <a href="http://dipankar08.github.io/apps/interview/index.html">Interview Prep</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/compiler/index.html">Online Compiler</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/simpledraw/index.html">QuickDraw</a>
              </li>
              <li>
                <a href="http://dipankar08.github.io/apps/learling/index.html">Tutorial</a>
              </li>
            </ul>
            <ul>
              <li className="head">Apps</li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=in.peerreview.fmradioindia">FM Radio.</a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=in.peerreview.khabar">Khabar app</a>
              </li>
            </ul>
            <ul>
              <li className="head">RealTime</li>
              <li>
                <a href="apps/realtime/fmradio.html">FM Radio</a>
              </li>
              <li>
                <a href="apps/realtime/news.html">News</a>
              </li>
            </ul>
          </div>
          <ul className="flex-row" style={{ margin: "50px auto", width: "200px" }}>
            <li>
              <a href="https://www.facebook.com/dipankar089">
                <i className="fab fa-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/dipankar08/">
                <i className="fab fa-linkedin" />
              </a>
            </li>
            <li>
              <a href="https://github.com/dipankar08/">
                <i className="fab fa-github" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
